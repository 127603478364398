import { toCamelCase } from '@/helpers/helpers';
import { getIdb } from '@/idb';
import { DateTime } from 'luxon';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	checkIfAnyOutOfDate() {
		return this.checkIfOutOfDate([
			'companyLocations',
			'inspectionItems',
			'inventory',
			'paymentTerms',
			'roles',
			'services',
			'settings',
			'tankTypes',
			'users',
			'vehicleMaintenanceTypes',
			'vehicles',
			'vehicleTypes',
			'wasteDisposalSites',
			'wasteDisposalSiteTypes',
			'wasteTypes',
			'rentalItemTypes',
			'rentalItems',
			'workOrderTypes',
		]);
	},
	/**
	 * Check the idb cache for the following APIs and updates the idb cache timestamp if the cache item is out-of-date
	 * @param {string[]} keys cache keys to check
	 */
	async checkIfOutOfDate(keys) {
		if (keys.length === 0) return;
		const idb = await getIdb();
		if (!idb) return;
		let cacheTimestamps = await idb.get('localInfo', 'cacheTimestamps');
		if (!cacheTimestamps) return;
		const model = {};
		let hasKey = false;
		for (const key of keys) {
			if (cacheTimestamps[key]) {
				model[key] = cacheTimestamps[key];
				hasKey = true;
			}
		}
		if (!hasKey) return;

		let response;
		try {
			response = await fetchWrap('/api/Cache/OutOfDate', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			return;
		}
		if (response.ok) {
			const data = await response.json();
			if (Array.isArray(data) && data.length > 0) {
				// get this from idb again since it could have changed
				cacheTimestamps = (await idb.get('localInfo', 'cacheTimestamps')) ?? {};
				for (const key of data) {
					delete cacheTimestamps[toCamelCase(key)];
				}
				await idb.put('localInfo', cacheTimestamps, 'cacheTimestamps');
			}
		}
	},
	async invalidateAllCaches() {
		let response;
		try {
			response = await fetchWrap('/api/Cache/InvalidateAllCaches', { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	/**
	 * Set the last time an API response was cached
	 * @param {string} key cache key
	 * @param {DateTime} value timestamp
	 */
	async setTimestamp(key, value) {
		const idb = await getIdb();
		if (!idb) return;
		const cacheTimestamps = (await idb.get('localInfo', 'cacheTimestamps')) ?? {};
		cacheTimestamps[key] = value.toISO();
		await idb.put('localInfo', cacheTimestamps, 'cacheTimestamps');
	},
	/**
	 * Clear the last time an API response was cached
	 * @param {string} key cache key
	 */
	async clearTimestamp(key) {
		const idb = await getIdb();
		if (!idb) return;
		const cacheTimestamps = (await idb.get('localInfo', 'cacheTimestamps')) ?? {};
		if (key in cacheTimestamps) {
			delete cacheTimestamps[key];
			await idb.put('localInfo', cacheTimestamps, 'cacheTimestamps');
		}
	},
	/**
	 * Check if an API was cached and is not out-of-date
	 * @param {string} key cache key
	 * @returns {Promise<boolean>} true if the cache can be used, otherwise false
	 */
	async isCacheHit(key) {
		const idb = await getIdb();
		if (!idb) return false;
		const cacheTimestamps = (await idb.get('localInfo', 'cacheTimestamps')) ?? {};
		return cacheTimestamps[key] && DateTime.fromISO(cacheTimestamps[key]).isValid;
	},
	/**
	 * Check if API requests can be cached
	 * @returns {Promise<boolean>} true if the cache can be used, otherwise false
	 */
	async canCache() {
		const idb = await getIdb();
		if (!idb) return false;
		return !!(await idb.get('localInfo', 'cacheTimestamps'));
	}
};
