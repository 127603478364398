import PaymentProcessor from './PaymentProcessor.js';
import WorkOrderPdfStyle from './WorkOrderPdfStyle.js';
import { getArrayFromDto as getArray, getValueFromDto as getValue } from './_helpers.js';

export default class Settings {
	constructor(dto) {
		this.companyName = getValue(dto, 'companyName', 'string', '');
		this.timeZone = getValue(dto, 'timeZone', 'string', '');
		this.dashboardEstimateAgeLimit = getValue(dto, 'dashboardEstimateAgeLimit', 'number', 0);
		this.geocodioApiKey = getValue(dto, 'geocodioApiKey', 'string', '');
		this.googleApiKey = getValue(dto, 'googleApiKey', 'string', '');
		this.modules = getArray(dto, 'modules', []);
		this.defaultState = getValue(dto, 'defaultState', 'string', null);
		this.showSetupGuide = getValue(dto, 'showSetupGuide', 'boolean', false);
		this.bccEmails = getValue(dto, 'bccEmails', 'boolean', false);
		this.ipAddressRestrictions = getArray(dto, 'ipAddressRestrictions');
		this.fromEmailAddress = getValue(dto, 'fromEmailAddress', 'string', '');
		this.testMode = getValue(dto, 'testMode', 'boolean', false);
		this.paymentCardBrandsAccepted = getArray(dto, 'paymentCardBrandsAccepted');
		this.paymentCreditCardProcessor = getValue(dto, 'paymentCreditCardProcessor', 'number', PaymentProcessor.none);
		this.authorizeNetCaptureFunds = getValue(dto, 'authorizeNetCaptureFunds', 'boolean', false);
		this.authorizeNetApiLoginId = getValue(dto, 'authorizeNetApiLoginId', 'string', '');
		this.authorizeNetTransactionKey = getValue(dto, 'authorizeNetTransactionKey', 'string', '');
		this.authorizeNetPublicClientKey = getValue(dto, 'authorizeNetPublicClientKey', 'string', '');
		this.heartlandCaptureFunds = getValue(dto, 'heartlandCaptureFunds', 'boolean', false);
		this.heartlandSecretKey = getValue(dto, 'heartlandSecretKey', 'string', '');
		this.heartlandPublicKey = getValue(dto, 'heartlandPublicKey', 'string', '');
		this.operatorInChargeId = getValue(dto, 'operatorInChargeId', 'number', null);
		this.defaultOperatorId = getValue(dto, 'defaultOperatorId', 'number', null);
		this.defaultVehicleId = getValue(dto, 'defaultVehicleId', 'number', null);
		this.requireOperatorPunch = getValue(dto, 'requireOperatorPunch', 'boolean', false);
		this.showOperatorsFutureWorkOrders = getValue(dto, 'showOperatorsFutureWorkOrders', 'boolean', false);
		this.inspectionDisclaimer = getValue(dto, 'inspectionDisclaimer', 'string', '');
		this.onlinePaymentUrl = getValue(dto, 'onlinePaymentUrl', 'string', '');
		this.businessLicenseNumber = getValue(dto, 'businessLicenseNumber', 'string', '');
		this.paymentDisclaimer = getValue(dto, 'paymentDisclaimer', 'string', '');
		this.workOrderPdfStyle = getValue(dto, 'workOrderPdfStyle', 'number', WorkOrderPdfStyle.standard);
		this.hasLandDisposalSite = getValue(dto, 'hasLandDisposalSite', 'boolean', false); // PDF only
		this.workOrderTypeUnassignedColor = getValue(dto, 'workOrderTypeUnassignedColor', 'string', '');
		this.dispatchMapDefaultDateRange = getValue(dto, 'dispatchMapDefaultDateRange', 'string', '');
	}
}
